<template>
	<el-form :model="fileForm" ref="fileForm" label-width="100px">
		<div v-for="(item, index) in fileDataForm" :key="index">
			<el-form-item :label="item.label" v-if="item.isInput != false" :rules="item.rules" :prop="item.attr">
				<el-input v-if="item.type == 'textarea'" type="textarea" v-model="fileForm[item.attr]" :placeholder="item.placeholder"></el-input>
				<el-select v-else-if="item.type == 'enum'" v-model="fileForm[item.attr]" :placeholder="item.placeholder" filterable>
					<el-option v-for="ite in item.dic" :key="ite.value" :label="ite.title" :value="ite.value"> </el-option>
				</el-select>
				<el-upload
					v-else-if="item.type == 'uploadfile'"
					:action="`${this.$CONFIG.API_URL}/File/UploadBidFile`"
					:on-success="uploadSuccess"
					:before-upload="beforeUploadFile"
					:headers="headers"
					:on-change="handleChange"
					:file-list="fileList"
					:before-remove="removeFile"
					drag
				>
					<i class="el-icon-upload"></i>
					<div class="el-upload__text">
						将文件拖到此处或<em>点击上传</em>
						<div class="el-upload__tip">
							请上传100M以内的文件！
						</div>
					</div>
				</el-upload>
				<el-input v-else v-model="fileForm[item.attr]" :placeholder="item.placeholder"></el-input>
			</el-form-item>
		</div>
		<el-form-item>
			<el-button type="primary" @click="submitForm('fileForm')">保存</el-button>
			<el-button @click="resetForm('fileForm')">重置</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
export default {
	name: "uploadBidFile",
	props: {
		fileId: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			headers: {},
			fileData: {},
			fileList: [],
			fileForm: {
				Id: 0,
				FileName: "",
				BidFileType: "",
				FileId: 0,
				RemarkInfo: "",
			},
			fileDataForm: [
				{
					label: "Id",
					attr: "Id",
					isInput: false,
				},
				{
					label: "存档名称",
					attr: "FileName",
					placeholder: "请输入存档名称",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请输入存档名称",
						},
						{
							min: 1,
							max: 50,
							message: "长度不大于50个字符",
							trigger: "blur",
						},
					],
				},
				{
					label: "存档类型",
					attr: "BidFileType",
					placeholder: "请选择存档类型",
					rules: [
						{
							required: true,
							message: "请选择存档类型!",
							trigger: "change",
						},
					],
					type: "enum",
					dic: this.GetBidFileTypes(),
				},
				{
					label: "存档文件",
					attr: "FileId",
					placeholder: "请上传文件!",
					rules: [
						{
							required: true,
							trigger: "blur",
							message: "请上传文件!",
						},
					],
					type: "uploadfile",
				},
				{
					label: "备注",
					attr: "RemarkInfo",
					placeholder: "请输入备注（长度不大于100个字符）",
					rules: [
						{
							min: 0,
							max: 100,
							message: "长度不大于100个字符",
							trigger: "blur",
						},
					],
					type: "textarea",
				},
			],
		};
	},
	mounted() {
		this.$nextTick(function() {
			// 仅在整个视图都被渲染之后才会运行的代码
			let adminInfo = this.$TOOL.data.get("adminInfo");
			this.headers = {
				Authorization: "Bearer " + adminInfo.JwtToken,
			};
		});
	},
	methods: {
		initEditModel() {
			var that = this;
			if (that.$props.fileId != 0) {
				that.$API.BidFiles.QueryById.get(that.$props.fileId).then((res) => {
					if (res) {
						that.fileForm = res;
						that.fileData.Id = res.FileId;
					}
				});
			}
		},
		handleChange(file, fileList) {
			this.fileList = fileList.slice(-1);
		},
		removeFile() {
			this.fileList = [];
			this.fileData = {};
		},
		uploadSuccess(res) {
			if (res.Success && res.Code == 200) {
				this.fileData = res.Data;
			} else {
				console.log(res.Message);
			}
		},
		GetBidFileTypes() {
			var data = [];
			this.$API.BidFiles.GetBidFileTypes.get().then((res) => {
				if (res) {
					res.forEach((it) => {
						data.push({ value: it.Key, title: it.Value });
					});
				}
				this.initEditModel();
			});
			return data;
		},
		beforeUploadFile(file) {
			const fileType = file.type;
			var fileTypes = [
				"image/jpg",
				"image/png",
				"image/jpeg",
				"image/gif",
				"image/bmp",
				"text/plain",
				"application/pdf",
				"application/msword",
				"application/vnd.ms-excel",
				"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				"application/vnd.openxmlformats-officedocument.wordprocessingml.document",
				"application/vnd.openxmlformats-officedocument.presentationml.presentation",
				"application/vnd.ms-powerpoint",
				"application/zip",
				"application/x-zip-compressed",
			];
			const isExcel = fileTypes.includes(fileType);
			const isLt100M = file.size / 1024 / 1024 / 100 < 100;

			if (!isExcel) {
				this.$message.warning("文件格式不支持!");
			}
			if (!isLt100M) {
				this.$message.warning("文件大小不能超过 1GB!");
			}
			return isExcel && isLt100M;
		},
		submitForm(formName) {
			var that = this;
			if (!that.fileData.Id) {
				that.$message.warning("请上传文件!");
				return;
			}
			that.fileForm.FileId = that.fileData.Id;
			that.$refs[formName].validate((valid) => {
				if (valid) {
					that.$API.BidFiles.AddOrUpdate.post(that.fileForm).then((res) => {
						if (res.Success) {
							that.$message.success(res.Message);
							that.$emit("operationSuccess");
						} else {
							that.$message.error(res.Message);
						}
					});
				} else {
					console.log("error submit!!");
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
			this.removeFile();
		},
	},
};
</script>

<style></style>
